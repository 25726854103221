import React from "react";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import "../assets/css/contactform.css";

// const classes = makeStyles();

class ContactForm extends React.Component {
    constructor(props) {
      super(props);
      // this.state = {
      //   firstName: props.firstName, 
      //   lastName: props.lastName, 
      //   email: props.email, 
      //   subject: props.subject, 
      //   message: props.message
      // };
      
      this.WelcomeMessage = props.WelcomeMessage;

      this.state = {
        firstName: '', 
        lastName: '', 
        email: '', 
        subject: '', 
        message: ''
      };

      //this.state = {};

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleChange(event) {
      this.setState({ [event.target.name] : event.target.value});
    }

    
    validateFields(){
        
    }
  
    handleSubmit(event) {
      //alert('A name was submitted: ' + this.state.firstName + this.state.lastName + this.state.email + this.state.subject + this.state.message);
      this.props.onSubmit({firstName: this.state.firstName, lastName: this.state.lastName, email: this.state.email, subject: this.state.subject, message: this.state.message})
      event.preventDefault();
    }
    
    makeStyles = ((theme) => ({
      root: {
        '& > *': {
          margin: theme.spacing(1),
          width: '25ch',
        },
      },
    }));
  
    render() {
      return (
        <>
          <form id="contactForm" className="contact-form" onSubmit={this.handleSubmit}> 
            <label className="welcome-message">{this.WelcomeMessage}</label>
            <fieldset className="fieldset-fb-twofields"><TextField name="firstName" value={this.state.firstName} onChange={this.handleChange} required label="First Name" variant="outlined" />
            <TextField name="lastName" value={this.state.lastName} onChange={this.handleChange} required label="Last Name" variant="outlined" /> </fieldset>
            <TextField name="email" value={this.state.email} onChange={this.handleChange} required label="Email" variant="outlined" />
            <TextField name="subject" value={this.state.subject} onChange={this.handleChange} required label="Subject" variant="outlined" multiline rows={4} />
            <Button variant="outlined" type="submit" form="contactForm">Submit</Button>
          </form>
        </>
      );
    }
  }

  export default ContactForm;