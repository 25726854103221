import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import "../assets/css/main.css";
import DOMPurify from "dompurify"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import "../assets/css/contactpage.css";
import ContactForm from "../components/contact-form"
import axios from "axios";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const contactUrl = process.env.API_URL + "/"; 
const strapiContactUrl = process.env.GATSBY_STRAPI_API_URL + "/"; 

const ContactPage = () => {
    const data = useStaticQuery(query);
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
      setAnchorEl(null);
    };

    function onSubmit(data){

        const contactData = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            subject: data.subject,
            message: data.message
        }

        axios({
            method: "post",
            url: strapiContactUrl + "contact-emails/",
            data: contactData,
            headers: { "Content-Type": "text/json" },
          })
        .then(function (response) {
            //handle success
            console.log("Success: " + response);
        })
        .catch(function (response) {
            //handle error
            console.log("Error: " + response);
        });
    }
  
    return (
      <Layout>
        <div className="uk-section">
          <div className="uk-container uk-container-large">
            <div className="contact-container">
                <img className="contact-welcomeimage" src={data.strapiContactPage.WelcomeImage.url} />
                <ContactForm onSubmit={onSubmit} WelcomeMessage={data.strapiContactPage.WelcomeMessage} />     
            </div>
          </div>
        </div>
      </Layout>
    );
};

const query = graphql`
    query {   
        strapiContactPage  {
            WelcomeImage {
              url
            }
            WelcomeMessage
        }    
    }
`;

 

  export default ContactPage;